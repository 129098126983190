import { useAppSelector } from 'hooks/store';
import React from 'react';
import { Socket } from 'socket.io-client';
import { selectSlideStatus } from 'store/slice/pluginStatus.slice';

const HtmlContent = ({
  htmlLink,
  classId,
  iframeRef,
  currentState,
}: {
  socket: Socket | undefined;
  htmlLink: string;
  classId: string;
  currentState: string;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}) => {
  const selectedSlideStatus = useAppSelector(selectSlideStatus);

  // Construct iframe link
  const link =
    htmlLink +
    '?classId=' +
    classId +
    '&classType=inClass&userType=student&classPhase=' +
    (currentState ? currentState : 'teach') +
    '&forTM=true';
  return (
    <div
      className={`${
        selectedSlideStatus?.show
          ? 'h-[100vh] grid grid-rows-[auto,1fr] opacity-100'
          : 'h-[0vh] opacity-0'
      }`}
    >
      <div className='pt-3 subHeading'>
        <span className='px-4 text-lg font-semibold text-white'>Notes</span>
      </div>
      <iframe
        src={link}
        ref={iframeRef}
        title="Tutor's slide"
        allowFullScreen={true}
        className='w-full h-full mx-2'
        style={{
          pointerEvents: 'none',
          height: selectedSlideStatus?.show ? '90vh' : '0vh',
        }}
      ></iframe>
    </div>
  );
};

export default HtmlContent;
