import { Socket } from 'socket.io-client';
import { datadogLogs } from '@datadog/browser-logs';
import { useRef, useEffect } from 'react';
import useMeeting from './useMeeting';
import { VAD_SOCKET_SERVER_URL } from '../configs/config';

interface TalkTimeData {
  participantId: string;
  total_talk_time: number;
  total_session_duration: number;
  recent_talk_time: number;
  from_timestamp: number;
  to_timestamp: number;
  transcribed_speech: string;
}

interface AudioBuffer {
  data: Float32Array;
  position: number;
}

const CHUNK_DURATION = 10000; // 10 seconds in milliseconds
const SAMPLE_RATE = 48000;
const BUFFER_SIZE = SAMPLE_RATE * 10; // 10 seconds of audio
const API_ENDPOINT = VAD_SOCKET_SERVER_URL || 'http://localhost:5000';

export const useStudentTalkTime = (socket: Socket | undefined) => {
  const { classId, joinedParticipants } = useMeeting();
  const audioBuffers = useRef(new Map<string, AudioBuffer>());
  const processingTimers = useRef(new Map<string, NodeJS.Timeout>());
  const isProcessing = useRef(new Map<string, boolean>());
  const processorNodes = useRef(new Map<string, AudioWorkletNode>());

  const initializeBuffer = (participantId: string) => {
    if (!audioBuffers.current.has(participantId)) {
      audioBuffers.current.set(participantId, {
        data: new Float32Array(BUFFER_SIZE),
        position: 0,
      });
    }
  };

  const addToBuffer = (participantId: string, newData: Float32Array) => {
    const buffer = audioBuffers.current.get(participantId);
    if (!buffer) return;

    // If buffer is full or nearly full, process it
    if (buffer.position + newData.length >= BUFFER_SIZE) {
      if (!isProcessing.current.get(participantId)) {
        void processBuffer(participantId);
      }
      buffer.position = 0;
    }

    // Copy new data into buffer
    buffer.data.set(newData, buffer.position);
    buffer.position += newData.length;
  };

  const processAudioTrack = (
    participantId: string,
    audioTrack: MediaStreamTrack,
    audioContext: AudioContext,
  ) => {
    try {
      initializeBuffer(participantId);

      const mediaStream = new MediaStream([audioTrack]);
      const source = audioContext.createMediaStreamSource(mediaStream);
      const processor = new AudioWorkletNode(audioContext, 'audio-processor');

      processorNodes.current.set(participantId, processor);

      processor.port.onmessage = (event) => {
        try {
          const audioData = event.data;
          if (!Array.isArray(audioData)) return;

          // Convert array to Float32Array for efficiency
          const float32Data = new Float32Array(audioData);
          addToBuffer(participantId, float32Data);

          // Start timer if not already started
          if (!processingTimers.current.has(participantId)) {
            const timer = setInterval(() => {
              if (!isProcessing.current.get(participantId)) {
                void processBuffer(participantId);
              }
            }, CHUNK_DURATION);
            processingTimers.current.set(participantId, timer);
          }
        } catch (error) {
          datadogLogs.logger.error('Error processing audio message', {
            participantId,
            error: error instanceof Error ? error.message : String(error),
          });
        }
      };

      source.connect(processor);
    } catch (error) {
      datadogLogs.logger.error('Error setting up audio processing', {
        participantId,
        error: error instanceof Error ? error.message : String(error),
      });
    }
  };

  const processBuffer = async (participantId: string) => {
    const buffer = audioBuffers.current.get(participantId);
    if (!buffer || buffer.position === 0) return;

    try {
      isProcessing.current.set(participantId, true);

      // Create a copy of the filled portion of the buffer
      const bufferToSend = buffer.data.slice(0, buffer.position);
      buffer.position = 0;

      const [userType, classIdPart, userId] = participantId.split('-');

      const response = await fetch(`${API_ENDPOINT}/process-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          audio_data: Array.from(bufferToSend), // Convert to regular array for JSON
          session_id: participantId,
          sample_rate: SAMPLE_RATE,
          user_type: userType,
          class_id: classIdPart,
          user_id: userId,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: TalkTimeData = await response.json();

      if (socket && data) {
        socket.emit('talkTimeData', { data });
      }
    } catch (error) {
      datadogLogs.logger.error('Failed to process audio buffer', {
        participantId,
        error: error instanceof Error ? error.message : String(error),
      });
    } finally {
      isProcessing.current.set(participantId, false);
    }
  };

  useEffect(() => {
    const audioContext = new window.AudioContext({ sampleRate: SAMPLE_RATE });

    const setupAudio = async () => {
      try {
        if (audioContext.state === 'suspended') {
          await audioContext.resume();
          datadogLogs.logger.info('AudioContext resumed', {
            sampleRate: audioContext.sampleRate,
            classId,
          });
        }

        await audioContext.audioWorklet.addModule('/audio-processor.js');

        joinedParticipants.forEach((participant) => {
          const { presetName, customParticipantId, audioTrack } = participant;
          const userType = presetName === 'group_call_host' ? 'tutor' : 'student';
          const participantId = `${userType}-${classId}-${customParticipantId?.split('-').pop()}`;

          if (audioTrack) {
            processAudioTrack(participantId, audioTrack, audioContext);
          }
        });
      } catch (error) {
        datadogLogs.logger.error('Error setting up audio context', {
          error: error instanceof Error ? error.message : String(error),
        });
      }
    };

    setTimeout(setupAudio, 1000);

    return () => {
      processorNodes.current.forEach((node, participantId) => {
        try {
          node.disconnect();
          node.port.close();
        } catch (error) {
          datadogLogs.logger.error('Error cleaning up processor node', {
            participantId,
            error: error instanceof Error ? error.message : String(error),
          });
        }
      });

      processingTimers.current.forEach(clearInterval);

      processorNodes.current.clear();
      processingTimers.current.clear();
      audioBuffers.current.clear();
      isProcessing.current.clear();

      audioContext.close().catch((error) => {
        datadogLogs.logger.error('Error closing audio context', {
          error: error instanceof Error ? error.message : String(error),
        });
      });
    };
  }, [joinedParticipants.length]);
};
