import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import './timeComponent.css';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function TimeComponent() {
  const timeFormat = 'HH:mm:ss:SSS';

  const getCurrentIST = () => {
    const now = dayjs.tz(new Date(), 'Asia/Kolkata');
    return {
      date: now.format('ddd, MMM D, YYYY'),
      time: now.format(timeFormat),
    };
  };

  const [currentTimeStr, setCurrentTime] = useState(getCurrentIST);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentIST);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='timeSpan'>
      <h6>Date: {currentTimeStr.date}</h6>
      <h6>Current Time: {currentTimeStr.time}</h6>
    </div>
  );
}
