import { Socket } from 'socket.io-client';
import { HTMLAttributes, useEffect, useRef } from 'react';

import { DyteParticipantTile } from '@dytesdk/react-ui-kit';
import { PRESET_TYPE } from 'configs/meeting';
import useMeeting from 'hooks/useMeeting';
import { useMeetingStore } from 'lib/meeting-store';
import { NODE_ENV } from 'configs';
import { useStudentTalkTime } from 'hooks';
import { detectEmotionsFromStream } from 'utils';

export default function ActiveSpeaker(
  props: Omit<HTMLAttributes<HTMLDyteParticipantTileElement>, 'style'> & {
    isSmall?: true;
    // setDetectedEmotionsMap: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    socket: Socket | undefined;
  },
) {
  const { isSmall, socket } = props;

  const processedStudentsRef = useRef(new Map());
  const { meeting, classId, joinedParticipants, socketUser, tutorId } = useMeeting();

  const [size, states] = useMeetingStore((s) => [s.size, s.states]);

  if (socketUser !== PRESET_TYPE.VIEWER) {
    useStudentTalkTime(socket);
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;

      const filteredMessages = meeting?.chat?.messages?.filter((message) =>
        message?.targetUserIds?.includes(`${meeting?.self?.userId}`),
      );

      if (event.data?.type === 'SEND_MESSAGE') {
        try {
          meeting.chat.sendMessage({ type: 'text', message: data?.payload }, [`${tutorId}`]);
        } catch (error) {
          console.error('Failed to send message:', error);
        }
      }
      if (event.data?.type === 'FETCH_CHAT') {
        parent?.postMessage(
          {
            type: 'CHAT_RESPONSE',
            payload: filteredMessages,
          },
          '*',
        );
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [tutorId]);

  useEffect(() => {
    if (socketUser !== PRESET_TYPE.VIEWER && NODE_ENV === 'prod') {
      joinedParticipants?.forEach((participant) => {
        if (participant?.videoTrack && !processedStudentsRef.current.has(participant.id)) {
          processedStudentsRef.current.set(participant.id, true);
          const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
          detectEmotionsFromStream(
            participant.videoTrack,
            // setDetectedEmotionsMap,
            socket,
            userType,
            participant.customParticipantId?.split('-').pop(),
            String(classId),
          );
        }
      });
    }
  }, [joinedParticipants]);

  return (
    <div className='flex flex-col justify-center h-screen px-6 py-6'>
      {joinedParticipants?.map((participant) => (
        <DyteParticipantTile
          key={participant.id}
          participant={participant}
          meeting={meeting}
          size={isSmall ? 'lg' : size}
          states={states}
          {...props}
          className='my-2'
        />
      ))}
    </div>
  );
}
